import httpClient  from '../api'; 
const API_CONTROLLER = 'settings/'; 


export default { 
    update(data) { 
        let url = API_CONTROLLER + 'update';
        return httpClient.post(url, data);
    },

    getByKey(key) { 
        let url = API_CONTROLLER + 'get-by-key';
        return httpClient.get(url, {
            params: {
                key: key
            }
        });
    },

    getAll() { 
        let url = API_CONTROLLER + 'get-all';
        return httpClient.get(url);
    },
}