<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách thiết lập</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary"> 
                            <md-icon>cached</md-icon> 
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button> 
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="table-content">
					<table class="data-table">
						<thead>
                            <tr>
                                <th style="width: 100px;">#</th>
                                <th style="width: 30%; text-align: left;">Mô tả</th>
                                <th style="width: 20%; text-align: left;">Key</th>
                                <th style="width: 30%; text-align: left;">Giá trị</th>
                                <th style="width: 15%;">Hành động</th>
                            </tr>
						</thead>
                        <tbody v-if="loadding == true" style="height: 150px;">
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                        </tbody>
                        <tbody v-if="loadding == false && data.length == 0" style="height: 150px;">
                            <tr><td colspan="100" class="no-data"><span>Không có dữ liệu</span></td></tr>
                        </tbody>
						<tbody v-if="loadding == false && data.length > 0">
							<tr v-for="(item, index) in data" :key="item.id" :class="{ 'odd': index % 2 !== 0 }">
								<td class="center">{{index + 1}}</td>
                                <td class="left">{{item.settingTitle}}</td>
								<td class="left">{{item.settingKey}}</td>
                                <td class="left">{{item.settingValue}}</td>
								<td class="action">
                                    <md-button @click="edit(item)" class="md-fab md-mini md-second">
                                        <md-icon>edit</md-icon>
                                        <md-tooltip>Sửa</md-tooltip>
                                    </md-button>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
            </div>
        </div>
        <crudForm ref="crudForm" @refresh="refresh" />
    </div>
</template>

<script>
import settingsService from '../../../api/settingsService';
import messageBox from '../../../utils/messageBox';
import { mapActions } from 'vuex';
import crudForm from './Crud.vue';

export default {
    components: {
        crudForm
    },
    metaInfo: {
        title: 'Thiết lập'
    },
    data() {
        return {
            loadding: false,
            data: [],
            id: 0
        }
    },
    created(){
        this.getData();
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        edit(obj){
                this.$refs.crudForm.open(obj);
            },

        refresh(){
            this.getData();
        },

        getData(){
            this.loadding = true;
            settingsService.getAll().then((response) => {
                if(response.statusCode == 200){
                    this.data = response.data;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.loadding = false; });
        },
    },
}
</script>