<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false">
        <md-content>
            <div class="dialog-header">
                <div class="dialog-title">{{ title }}</div>
                <div class="dialog-close" @click="showDialog = false">
                    Close
                </div>
            </div>
            <md-content class="md-scrollbar">
                <div class="dialog-content">
                    <div class="row">
                        <div class="col l-12 m-12 c-12">
                            <md-field>
                                <label for="settingKey">Setting Key</label>
                                <md-input disabled name="settingKey" v-model="entity.settingKey"></md-input>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.settingTitle.$error }">
                                <label for="settingTitle">Mô tả</label>
                                <md-input name="settingTitle" v-model="entity.settingTitle"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.settingTitle.required">Vui lòng nhập mô tả</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.settingValue.$error }">
                                <label for="settingValue">Giá trị</label>
                                <md-input name="settingValue" v-model="entity.settingValue"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.settingValue.required">Vui lòng nhập giá trị</span>
                            </md-field>
                        </div>
                    </div>
                </div>
            </md-content>
            <div class="dialog-actions">
                <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                <md-button v-shortkey="['ctrl', 'đ']" @shortkey="showDialog = false" @click="showDialog = false" class="md-raised"><span>Đ</span>óng<md-tooltip>Đóng (Ctrl + Đ)</md-tooltip></md-button>
            </div>
        </md-content>
    </md-dialog>
</template>
<script>
    import settingsService from '../../../api/settingsService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';

    export default ({
        data() {
            return {
                showDialog: false,
                submitted: false,
                entity: { id: 0, settingTitle: '', settingKey: '', settingValue: '' },
            }
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            async open(obj){
                this.entity = obj;
                this.submitted = false;
                this.showDialog = true;
                this.setLoading(false);
            },

            close(){
                this.showDialog = false;
            },

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                this.update();
                this.showDialog = false;
            },

            update(){
                this.setLoading(true);
                settingsService.update(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$emit('refresh');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
        validations: {
            entity: {
                settingTitle: { required },
                settingValue: { required }
            }
        }
     })

</script>

<style lang="css" scoped>
    .dialog-title {
        font-size: 16px;
    }
    .dialog-header {
        border-bottom: 1px solid #ccc;
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 15px;
    }
    .md-content {
        width: 800px;
        height: 430px;
        max-width: 800px;
    }
    .md-scrollbar {
        height: 340px;
        padding-bottom: 20px;
    }
    .dialog-content {
        padding: 10px 15px;
    }
    .dialog-actions {
        height: 35px;
        border-top: 1px solid #ccc;
        padding-top: 15px;
        display: flex;
        align-items: center;
        justify-content: end;
    }
</style>